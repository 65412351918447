import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui';


// 路由懒加载，对路径进行分组打包

const Login = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Login.vue')
const Home = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Home.vue')
const Welcome = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Welcome.vue')
const ChangePassword = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/ChangePassword.vue')

const Users = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/Users.vue')
const UserRole = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/UserRole.vue')
const WxUsers = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/WxUsers.vue')
const MyWxUsers = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/MyWxUsers.vue')
const AllWxUsers = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/AllWxUsers.vue')
const InsuranceDoc = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/InsuranceDoc.vue')

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    {
        path: '/home',
        component: Home,
        redirect: '/welcome',
        children: [
            { path: '/welcome', component: Welcome },
            { path: '/changepassword', component: ChangePassword },
            { path: '/users', component: Users },
            { path: '/userrole', component: UserRole },
            { path: '/wxusers', component: WxUsers },
            { path: '/mywxusers', component: MyWxUsers },
            { path: '/allwxusers', component: AllWxUsers },
            { path: '/insurancedoc', component: InsuranceDoc }

        ]
    },
    {
        path: '*',
        redirect: '/404',
        hidden: true
    }
]

const router = new VueRouter({
        mode: 'hash', //history
        base: process.env.BASE_URL,
        routes
    })
    // 挂载路由导航守卫 beforeEach,to:想去哪个路由来，from：从哪个路径来,next是一个函数，表示放行
router.beforeEach((to, from, next) => {
    if (to.path === '/login') return next();
    // 获取token
    // const tokenStr = window.sessionStorage.getItem('token');
    // if (!tokenStr) {
    //     Message.error("未取得有效token信息");
    //     return next('/login');
    // }
    // token有值，则放行

    next();
})
export default router