import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './plugins/element.js'
import 'default-passive-events'
import qs from 'qs'
// 导入全局样式表
import './assets/css/quill.css'
import './assets/css/global.css'
import './assets/iconfont/iconfont.css'
import axios from 'axios'
// import 'element-ui/lib/theme-chalk/index.css'
import global_ from './components/Global.vue'
import x2js from 'x2js' //xml数据处理插件
import md5 from 'js-md5'
// 引入公共方法扩展
import common from '@/utils/common.js'

//引入quill-editor编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

//实现quill-editor编辑器拖拽上传图片
import * as Quill from 'quill'
import { ImageDrop } from 'quill-image-drop-module'
Quill.register('modules/imageDrop', ImageDrop)

//实现quill-editor编辑器调整图片尺寸
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)



Vue.prototype.$common = common

// 全局挂载axios,给全局变量$http
Vue.prototype.$http = axios
Vue.prototype.$qs = qs
Vue.prototype.GLOBAL = global_
Vue.prototype.$x2js = new x2js() //全局挂载到vue原型上
Vue.prototype.$md5 = md5


// 导入NProgress进度条
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'

// 配置请求的根路径
// http://localhost:2781/api/  
//axios.defaults.baseURL = 'http://localhost:2781/api/'
axios.defaults.baseURL = 'https://huigongfu.2szs.cn:7443/api/'
axios.defaults.rootURL = 'https://huigongfu.2szs.cn:7443/'
    // 增加axios拦截器,config为请求的对象
axios.interceptors.request.use(config => {
    NProgress.start();

    // let yyyy = new Date().getFullYear();　　
    // let mm = new Date().getMonth() + 1;　　
    // let dd = new Date().getDate();　　
    // let hh = new Date().getHours();　　
    // let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();　　
    // let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
    // var gettime = yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;

    // var str = window.sessionStorage.getItem('userCode') + ',' +
    //     window.sessionStorage.getItem('userName') + ',' +
    //     window.sessionStorage.getItem('companyCode') + ',' +
    //     gettime + ',' +
    //     md5(window.sessionStorage.getItem('userCode') + 'qyy2022' + gettime) + "," +
    //     window.sessionStorage.getItem('isSale') + ',qyy2022';
    // config.headers.token = encodeURIComponent(str);

    config.headers.token = "4055F5C41E29B49D4CED1D9582B8C892";
    //   最后必须return config 固定写法
    return config;
})
axios.interceptors.response.use(config => {
    NProgress.done();
    return config;
})




Vue.config.productionTip = false
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')